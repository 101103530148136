<template>
    <div>
        <div v-if="isNoteAssessment === true">
            <div class="d-block d-lg-flex align-items-center mb-4">
                <h6 class="mb-2 mb-lg-0">{{ $t("evaluation") }}</h6>
                <span class="mx-3 text-black-35 small d-none d-lg-block">&#124;</span>
                <h6 class="mb-0 text-muted">{{ schedule_name }}</h6>
            </div>
            <b-table
                :empty-text="this.$t('no_result')"
                bordered
                striped
                responsive
                :items="items"
                :fields="fields"
                class="mb-0 table-dropdown no-scrollbar border rounded"
            >

                <template #cell(nextPage)="data">
                    <b-button
                        variant="outline-primary"
                        @click="
                        getExams(data.item, data.item.examName, data.item.isNewExam)
            "
                    >
                        <i class="ri-arrow-right-line"></i>
                    </b-button>
                </template>
            </b-table>
        </div>
        <div v-else>
            <div v-if="isNoteAssessmentDetail === true">
                <NoteAssessmentDetail
                    :items="itemNoteAssessmentDetail"
                    :name="name"
                    :isNewExam="isNewExam"
                    :class_id="class_id"
                    :schedule_name="schedule_name"
                    :exam_definition_id="exam_definition_id"
                    @getExam="getExam($event)"
                    @getExamName="getExamName($event)"
                />
            </div>
            <div v-else>
                <div class="spinner-border text-light mx-auto d-flex"></div>
            </div>
        </div>
    </div>
</template>
<script>
import NoteAssessmentDetail from "@/components/interactive-fields/NoteAssessmentDetail";

export default {
    components: {
        NoteAssessmentDetail,
    },

    props: {
        class_id: {
            type: Number,
            default: null,
        },

        schedule_name: {
            type: String,
            default: "",
        },

        items: {
            type: Array,
            default: null,
        },
    },

    data() {
        return {
            isNoteAssessment: true,
            isNoteAssessmentDetail: false,
            itemNoteAssessmentDetail: [],
            name: "",
            exam_definition_id: "",
            isNewExam: false,
            fields: [
                {
                    key: "examName",
                    label: this.toUpperCase("evaluation_type"),
                    sortable: true,
                },
                {
                    key: "numberOfExams",
                    label: this.toUpperCase("number_of_exams"),
                    sortable: true,
                },
                {
                    key: "numberOfDoneExams",
                    label: this.toUpperCase("number_of_done_exams"),
                    sortable: true
                },
                {
                    key: "effectRatio",
                    label: this.toUpperCase("exam_effect_ratio"),
                    sortable: true,
                },
                {
                    key: "nextPage",
                    label: "#",
                    sortable: false,
                    class: "p-2 text-center w-40 align-middle",
                },
            ],
        };
    },
    methods: {
        getExam(data) {
            this.$emit("getExam", data);
        },
        getExamName(data) {
            this.$emit("getExamName", data);
        },
        getExams(data, name, isNewExam) {
            this.itemNoteAssessmentDetail = data.exams;
            this.name = name;
            this.isNewExam = isNewExam;
            this.exam_definition_id = data.id;
            this.isNoteAssessment = false;
            this.isNoteAssessmentDetail = true;
        }
    },
};
</script>
