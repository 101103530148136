<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="d-block d-lg-flex align-items-center">
                <h6 class="mb-2 mb-lg-0">{{ $t("evaluation") }} - {{ name }}</h6>
                <span class="mx-3 text-black-35 small d-none d-lg-block">&#124;</span>
                <h6 class="mb-0 text-muted">{{ schedule_name }}</h6>
            </div>
        </div>
        <b-table
            :empty-text="$t('no_result')"
            bordered
            striped
            responsive
            :items="items"
            :fields="fields"
            class="mb-0 table-dropdown no-scrollbar border rounded"
        >
            <template #cell(status)="data">
                <div v-if="data.item.isFinalized === true">
                    <b-badge variant="success" class="text-uppercase">
	                    {{ toUpperCase("published") }}
                    </b-badge>
                </div>
                <div v-else>
                    <b-badge variant="danger" class="text-uppercase" v-if="data.item.id>0">
	                    {{ toUpperCase("unpublished") }}
                    </b-badge>
                    <b-badge variant="danger" class="text-uppercase" v-if="data.item.id==0">
	                    {{ toUpperCase("no_grades_entered") }}
                    </b-badge>
                </div>
            </template>

            <template #cell(nextPage)="data">
                <b-button variant="outline-primary" v-if="data.item.id>0" @click="getExam(data.item.id, name)">
                    <i class="ri-arrow-right-line"></i>
                </b-button>

                <b-button variant="outline-primary" v-if="data.item.isNewExam && data.item.id==0" @click="addExam(data.item)">
                    <i class="ri-arrow-right-line"></i>
                </b-button>
            </template>
        </b-table>

    </div>
</template>
<script>
import PrepExamNotes from "@/services/PrepExamNotes";
import moment from "moment";

export default {
    props: {
        name: {
            type: String,
            default: "",
        },

        schedule_name: {
            type: String,
            default: "",
        },

        class_id: {
            type: Number,
            default: null,
        },

        exam_definition_id: {
            type: Number,
            default: null,
        },

        items: {
            type: Array,
            default: null,
        },

    },

    data() {
        return {
            selectDate: moment(new Date()).format("YYYY-MM-DD"),
            isBtn: true,
            date: "",
            fields: [
                {
                    key: "assessment",
                    label: this.toUpperCase("evaluation"),
                    sortable: true,
                },
                {
                    key: "date",
                    label: this.toUpperCase("date"),
                    sortable: true,
                },
                {
                    key: "status",
                    label: this.toUpperCase("status"),
                    sortable: true,
                },
                {
                    key: "nextPage",
                    label: "#",
                    sortable: false,
                    class: "p-2 text-center w-40 align-middle",
                },
            ]
        };
    },
    methods: {
        showModal(modalName) {
            this.$refs[modalName].show();
        },
        hideModal(modalName) {
            this.$refs[modalName].hide();
        },
        getExam(id, name) {
            this.$emit("getExam", id);
            this.$emit("getExamName", name);
        },

        addExam(item) {
            let data = {
                exam_definition_id: parseInt(item.exam_definition_id),
                class_id: parseInt(this.class_id),
                date: this.selectDate,
            };

            PrepExamNotes.postPrepExamAdd(data)
                .then((res) => {
                    this.$toast.success(this.$t("api." + res.data.message));
                    item.date = this.selectDate;
                    item.id = res.data.data.id;
                    item.isNewExam = false;
                    let filtered = this.items.filter(fi => {
                        return fi.rank = item.rank + 1;
                    });
                    if (filtered.length) {
                        filtered[0].isNewExam = true;
                    }
                    this.getExam(item.id, item.assessment);
                })
                .catch((e) => {
                    if (e.data.message) {
                        this.$toast.error(this.$t("api." + e.data.message));
                    }
                });
        },
    },
};
</script>
